import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';
const firebaseConfig = {
  apiKey: "AIzaSyDNUlI3Uj5FRtc5V5nZjvV-UaBzTd2z0SM",
  authDomain: "frontpin-66719.firebaseapp.com",
  databaseURL: "https://frontpin-66719-default-rtdb.firebaseio.com",
  projectId: "frontpin-66719",
  storageBucket: "frontpin-66719.firebasestorage.app",
  messagingSenderId: "1024076656236",
  appId: "1:1024076656236:web:1df4d70e41144720513249",
  measurementId: "G-L461RXCL51"
};




const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export { messaging };