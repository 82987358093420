import React from "react";
import ReactDOM from 'react-dom/client';
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import { Provider } from "react-redux";
import {initializeApp} from 'firebase/app';
import 'firebase/database';
import store from "./store";
const firebaseConfig = {
  apiKey: "AIzaSyDNUlI3Uj5FRtc5V5nZjvV-UaBzTd2z0SM",
  authDomain: "frontpin-66719.firebaseapp.com",
  databaseURL: "https://frontpin-66719-default-rtdb.firebaseio.com",
  projectId: "frontpin-66719",
  storageBucket: "frontpin-66719.firebasestorage.app",
  messagingSenderId: "1024076656236",
  appId: "1:1024076656236:web:1df4d70e41144720513249",
  measurementId: "G-L461RXCL51"
};


// Initialize Firebase
const firebase =initializeApp(firebaseConfig);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider store={store}>
      <React.Fragment>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </React.Fragment>
    </Provider>
);

serviceWorker.unregister()
